/* You can add global styles to this file, and also import other style files */
// .redoc-logo {
//     display: inline-block;
//     height: 55px;
//     width: 170px;
//     background-image: url('assets/images/Apptium-logo-Blue_Orange.png');
//     background-size: cover;
//     position: fixed;
//   }

  ._auth-btn{
    padding: 5px;
    margin-right: 10px;
    line-height: 1;
    display: inline;
    color: #49cc90;
    border: 1px solid #49cc90;
    background-color: transparent;
    cursor: pointer;
    box-shadow: 0 1px 2px rgba(0,0,0,.1);
    font-weight: 700;
  }

   .jzKEBq{
    display: none !important;
  }